import axios from "@/axios"

const saveVideoTope = async (body) => {
	const data = await axios.post(`/api/videos/save-video-topes`, body)
	return data
}

const getVideoById = async (body) => {
	const data = await axios.post(`/api/videos/get-by-id`, body)
	return data
}

const updateVideoTope = async (body) => {
	const data = await axios.post(`/api/videos/update-video-topes`, body)
	return data
}

const updateDateVideoTope = async (body) => {
	const data = await axios.put(`/api/videos/update-data-videos`, body)
	return data
}

const getRivalsByName = async (body) => {
	const data = await axios.get(`/api/videos/get-rivals-by-name`, body)
	return data
}

const getVideosSpecimen = async ({
	fight_result_id,
	search,
	tournament_id,
	category_id,
	result_id,
	rival_id,
	start_date,
	end_date,
	perpage,
	page,
	group_category,
	specimen_id,
	reason_id,
}) => {
	try {
		const { data } = await axios.get(`/api/videos/get-all-videos`, {
			params: {
				fight_result_id,
				search,
				tournament_id,
				category_id,
				group_category,
				result_id,
				rival_id,
				start_date,
				end_date,
				perpage,
				page,
				specimen_id,
				reason_id,
			},
		})
		return data
	} catch (error) {}
}

const uploadVideo = async (body) => {
	try {
		const { data } = await axios.get(`/api/videos/upload-video`, body)
		return data
	} catch (error) {}
}

const removedConfrontation = async ({ id }) => {
	try {
		const { data } = await axios.post(`/api/confrontation/removed-confrontation/${id}`)
		return data
	} catch (error) {}
}

const uploadVideoConfrontation = async ({ confrontaion_id, video_id, name, path_s3 }) => {
	try {
		const { data } = await axios.put(`/api/confrontation/upload-video`, {
			confrontaion_id,
			video_id,
			name,
			path_s3,
		})
		return data
	} catch (error) {}
}
const getUrlMaxQuaility = async (uuid) => {
	try {
		const { data } = await axios.get(`/api/videos/get-url-max-quality/${uuid}`)
		return data
	} catch (error) {}
}

const getUrlForDownloadVideo = async (confrontaion_id) => {
	try {
		const { data } = await axios.get(`/api/videos/get-url-for-fownload/${confrontaion_id}`)
		return data
	} catch (error) {
		throw error
	}
}

const getCounterVideos = async () => {
	try {
		const data = await axios.get(`/api/videos/counter`)
		return data
	} catch (error) {
		throw error
	}
}

//removed-confrontation/{id}

export {
	saveVideoTope,
	getVideoById,
	updateVideoTope,
	updateDateVideoTope,
	getRivalsByName,
	getVideosSpecimen,
	uploadVideo,
	removedConfrontation,
	uploadVideoConfrontation,
	getUrlMaxQuaility,
	getUrlForDownloadVideo,
	getCounterVideos,
}
